const perms = [
    "shoporder:all",
    "curriculum:CategoryAdd",
    "template:edittemplate",
    "supplementary:supplementaryexam",
    "refund:approval",
    "exam:ManageAdd",
    "forntbusiness:auditionRecord",
    "question:ManageAdd",
    "curriculum:ManageAdd",
    "template:all",
    "cashPooling:CashRecharge",
    "area:memberMan",
    "class:all",
    "curriculum:addCourseStudent",
    "quesbank:ManageAdd",
    "exceptionCase:all",
    "testquestion:ManageAdd",
    "home:readPaper",
    "lesson:all",
    "lesson:campus",
    "class:teacher",
    "curriculum:CategoryEdit",
    "curriculum:courseCopy",
    "template:campus",
    "exam:ManageEdit",
    "area:delArea",
    "question:ManageEdit",
    "curriculum:ManageClose",
    "record:addthembstudent",
    "supplementary:myapplication",
    "home:lessonApply",
    "quesbank:ManageEdit",
    "forntbusiness:auditionLesson",
    "testquestion:ManageEdit",
    "class:campus",
    "area:editArea",
    "class:addnormal",
    "curriculum:CategoryDel",
    "template:my",
    "exam:ManageDel",
    "class:studentManageEdit",
    "lesson:myLesson",
    "question:ManageDel",
    "curriculum:ManageDel",
    "forntbusiness:auditionStatistic",
    "quesbank:ManageDel",
    "testquestion:ManageDel",
    "quesbank:ManageStop",
    "question:ManageStop",
    "class:studentHeadmasterEdit",
    "frontbusiness:lessonEdit",
    "exam:ManagePub",
    "statistics:allClassHour",
    "curriculum:ManageReturn",
    "class:add",
    "question:ManageTest",
    "class:editClassInfo",
    "exam:ManagePause",
    "class:recordManage",
    "statistics:myClassHour",
    "curriculum:ManageEdit",
    "frontbusiness:lessonLive",
    "record:addBatch",
    "frontbusiness:lessonLiveDelete",
    "class:recordStatistics",
    "class:del",
    "curriculum:LessonAdd",
    "curriculum:LessonEdit",
    "class:editLinkupRecord",
    "class:review",
    "frontbusiness:lessonLiveEdit",
    "class:delLinkupRecord",
    "audition:offline",
    "curriculum:LessonDel",
    "class:finishClass",
    "class:syncClassList",
    "curriculum:TaskAdd",
    "class:theSchoolStudent",
    "audition:online",
    "class:allStudent",
    "curriculum:TaskEdit",
    "class:divideintoClass",
    "curriculum:TaskDel",
    "class:leaveClassStu",
    "class:myStudent",
    "class:editClassStu",
    "class:updateTeachCourse",
    "class:delClassStu",
    "class:changeClass",
    "class:recordClassStu",
    "class:addLesson",
    "class:editLessonInfo",
    "class:delLesson",
    "class:disappearLesson",
    "lesson:addThembLessonMember",
    "class:delLessonStu",
    "class:thembChangeLesson",
    "class:callStudent",
    "class:showcheckboxAttendance",
    "class:updatestudentcourse",
    "class:studentcourse",
    "class:liveMan",
    "class:teaFreeTime",
    "class:roomFreeTime",
    "class:alldivideStudent",
    "page",
    "class:lessonListSort",
    "class:syncLessonPlan"
]

export {
    perms
};